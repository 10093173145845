<script setup lang="ts">
import type { BaseContent, Customer, File, NumeroRivista } from "@gasparigit/omnia-sdk";
import { cn } from "~/lib/utils";
import { downloadFile } from "~/lib/utils/downloader";

const omniaSdk = useOmniaSdk();

const props = defineProps<{
  content?: BaseContent | NumeroRivista;
  file?: File;
  fileId?: number;
  customer?: Customer;
  title?: string;
}>();

const currentFile = ref<File | null>(props.file || null);

const status = ref({
  downloading: false,
  percent: 0,
});

const fileName = computed(() => {
  let tempFileName = props.title || currentFile.value.title;

  const fileExtension = currentFile.value.file.extension?.toLowerCase();

  if (tempFileName && !tempFileName.endsWith(`.${fileExtension}`)) tempFileName += `.${fileExtension}`;

  return tempFileName;
});

async function download() {
  if (props.fileId && !currentFile.value) {
    currentFile.value = await omniaSdk.v1.files.get(props.fileId);
  }

  if (!currentFile.value.file) return;

  downloadFile(
    () =>
      props.content
        ? omniaSdk.v1.media.downloadFile(props.content.id, currentFile.value.id, false, props.customer?.id, "contentable_id" in props.content ? "content" : "numero-rivista")
        : omniaSdk.v1.media.downloadFileNoContent(currentFile.value.id, props.customer?.id),
    status,
    fileName.value,
    currentFile.value.file.mime_type
  );
}
</script>

<template>
  <button @click="download" :disabled="status.downloading" :class="cn('grid w-full gap-3 cursor-pointer', $attrs.class)">
    <div v-bind:class="$attrs.class" :class="{ 'animate-pulse': status.downloading }">
      <slot></slot>
    </div>
    <div v-if="status.downloading" class="px-1">
      <Progress :model-value="status.percent" class="h-1" />
    </div>
  </button>
</template>
